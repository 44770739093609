import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    type: '',
    config: {},
    metaInfo: {
      title: '',
      keywords: '',
      description: ''
    }
  },
  getters: {

  },
  mutations: {
    CHANGE_META_INFO(state, metaInfo) {
      state.metaInfo = metaInfo;
      console.log(state.metaInfo);
    }
  },
  actions: {
  },
  modules: {
  }
})


