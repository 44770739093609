import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        component: () => import('@/views/index/index.vue'),
        children:[
          {
            path: '/',
            component: () => import('@/views/index/Home.vue'),
          },
          
          {
            path: '/about',
            component: () => import('@/views/about/About.vue')
          },
          {
            path: '/news',
            component: () => import('@/views/news/News.vue')
          },
          {
            path: '/newsDetail',
            component: () => import('@/views/news/NewsDetail.vue')
          },
          {
            path: '/product',
            component: () => import('@/views/product/Product.vue')
          },
          {
            path: '/productDetail',
            component: () => import('@/views/product/ProduceDetail.vue')
          },
          {
            path: '/brand',
            component: () => import('@/views/brand/Brand.vue')
          },
          {
            path: '/paradise',
            component: () => import('@/views/paradise/Paradise.vue')
          },
          {
            path: '/paradiseDetail',
            component: () => import('@/views/paradise/ParadiseDetail.vue')
          },
          {
            path: '/careers',
            component: () => import('@/views/careers/Careers.vue')
          },
          {
            path: '/contactUs',
            component: () => import('@/views/contactUs/ContactUs.vue')
          },
        ]
    },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,  // 如果使用history模式，必须设置base参数
  routes
})

export default router
