import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
// 引用 flexible 插件
import "lib-flexible/flexible.js";
//引入jquery
import $ from 'jquery'
//引入bootstarp
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap-vue/dist/bootstrap-vue.js';
//引入swiper
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min.js'

import VueMeta from 'vue-meta'
Vue.use(VueMeta)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
//图片地址
Vue.prototype.imgUrl = 'https://zhunong.jxdinfen.com';
Vue.config.productionTip = false;
// 全局注册 $
Vue.prototype.$ = $
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});
//每次路由更新前，设置当前页面的meta信息
// router.beforeEach((to, from, next) => {
//   // debugger
//   if (to.meta.metaInfo) {
//     store.commit("CHANGE_META_INFO", to.meta.metaInfo);
//   }
//   next();
// });

new Vue({
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this//安装全局事件总线
  },
  router,
  store,
  // metaInfo() {
  //   return {
  //     title: this.$store.state.metaInfo.title,
  //     meta: [
  //       {
  //         name: "keywords",
  //         content: this.$store.state.metaInfo.keywords
  //       },
  //       {
  //         name: "description",
  //         content: this.$store.state.metaInfo.description
  //       }
  //     ]
  //   };
  // },
}).$mount('#app')
